// src/components/Dashboard.js
import React, { useState } from "react";
import { db, auth } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "./Submit.css";

function Submit() {
  const [balance, setBalance] = useState(0.0);
  const [amount, setAmount] = useState("");
  const [withdrawal, setWithdrawal] = useState("");
  const [method, setMethod] = useState("");
  const [initial, setInitial] = useState(0.0);
  const [profits, setProfits] = useState(0.0);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (user) {
      try {
        await addDoc(collection(db, "investment"), {
          amount,
          withdrawal,
          method,
          balance,
          initial,
          profits,
          userId: user.uid,
          createdAt: new Date(),
        });
        setAmount("");
        setWithdrawal("");
        setMethod("");
        setBalance(0.0);
        setInitial(0.0);
        setProfits(0.0);
        navigate("/pay");
      } catch (error) {
        alert(error.message);
      }
    } else {
      alert("You need to be logged in to submit investment.");
    }
  };

  return (
    <div className="submit-section">
      <div className="invest-section">
        <form onSubmit={handleSubmit} className="investment-form">
          <div className="dashHeader">
            <h2>Investment Registration</h2>
          </div>
          <label>Investment Amount</label>
          <input
            type="number"
            placeholder="Amount above $500"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
          <label>Profit Sharing Basis</label>
          <input
            type="text"
            placeholder="Either WEEKLY or MONTHLY"
            value={withdrawal}
            onChange={(e) => setWithdrawal(e.target.value)}
            required
          />

          <label>Profit Sharing Method</label>
          <input
            type="text"
            placeholder="USDT, DERIV TRANSFER, OTHER"
            value={method}
            onChange={(e) => setMethod(e.target.value)}
            required
          />
          <button type="submit">Register Investment</button>
        </form>
      </div>
    </div>
  );
}

export default Submit;
