// src/components/JobList.js
import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import './Dashboard.css'
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const Dashboard = () => {
  const [investments, setInvestments] = useState([]);
  const [user, setUser] = useState(null);
  const navigate=useNavigate();
 
  



  useEffect(() => {
    const fetchInvestments = async () => {
      const user = auth.currentUser;
    
      if (user) {
        setUser(user);
        const q = query(collection(db, 'investment'), where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const investmentList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setInvestments(investmentList);
        
      }
     
    }
      
    
    fetchInvestments();
  }, []);
 
  const handleWithdraw=()=>{
    toast.error("Account Error!", {position: "top-center"})
  }
  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        navigate('/');  // Redirect to login page after logout
      })
      .catch((error) => {
        console.error("Logout Error: ", error);
      });
  };


  return (
    <div className="dashboard">
      <div className='dashHeader'>
        <h3>Welcome,<span className='userEmail'>{user?.email}</span> </h3>
        
        
       
        
      </div>
      <div className='noticeboard'>
        <div className='navBtns'>
        <Link to ='/pay'><button className='dashBtn'>Make Payment</button></Link>
        <button className='dashBtn2' onClick={handleWithdraw} disable={true}>Withdraw </button>
        <button className='logoutBtn' onClick={handleLogout}>Logout</button>
        <ToastContainer/>
        </div>
        <p> <span>To New Members!</span> <br/>Balances will be updated Once you  <Link to='/invest'><span className='investLink'>Register an Investment Here</span></Link> </p>
      </div>
      
      <div className='status-display'>
            <span className='menu-container balanceBox'>Current Balance:<br/>{user?(<>
              {investments.map(investment => (
                <div key={investment.id} >
                 $ {investment.balance}
               </div>
             ))}
             </>
              
            ):(<><p>Loading...</p></>)} 
             </span>
            <span className='menu-container amountBox'>Initial Investment:
            {investments.map(investment => (
           <div key={investment.id} >
            $ {investment.amount}
            
          </div>
        ))} </span>
            <span className='menu-container basisBox'>Profits Gained : <br/>
            {investments.map(investment => (
           <div key={investment.id} >
            $ {investment.profits}
          </div>
        ))}</span>
            <span className='menu-container methodBox'>Total Withdrawals: <br/> 
            {investments.map(investment => (
           <div key={investment.id} >
             $ {investment.initial}
          </div>
        ))}</span> 
      </div>
      
    </div>
  );
};

export default Dashboard;
