import React, { useState } from 'react'
import './Navbar.css'
import logo from '../assets/logo.png'
import menu_icon from '../assets/menu-icon.png'
import { Link } from 'react-router-dom';




const Navbar = () => {
    const [mobileMenu, setMobileMenu]= useState(false)
    const toggleMenu=()=>{
        mobileMenu? setMobileMenu(false): setMobileMenu(true)
    }

  return (
    <nav className={'container '}>
        <Link to='/'><img src={logo} className="logo" alt='logo'/></Link>
        <ul className={mobileMenu? '': 'hide-mobile-menu'}>

            <li><Link to='https://360tradinghub.co.ke/bots/'>Trading Bots</Link></li>
            <li><Link to='/pay'>Payments</Link></li>
            <li><Link to='https://track.deriv.com/_UEAPSNb_-9UKqFKZ7JdnQ2Nd7ZgqdRLk/1/'>Open Trading Account</Link></li>
            <li className='btn'><Link to='/login'>Member Login</Link></li>
        </ul>
        <img src={menu_icon} alt=''className='menu-icon' onClick={toggleMenu}/>
    </nav>
  )
}

export default Navbar