import React, { useState } from "react";
import "./Calculator.css";
import { Link } from "react-router-dom";

const ProfitCalculator = () => {
  const [investment, setInvestment] = useState(0);
  const [dailyProfit, setDailyProfit] = useState(0);
  const [sixDayProfit, setSixDayProfit] = useState(0);

  const calculateProfits = (amount) => {
    const dailyProfit = (amount * 15) / 100;
    const sixDayProfit = (dailyProfit * 6)-20;
    setDailyProfit(dailyProfit);
    setSixDayProfit(sixDayProfit);
  };

  const handleInputChange = (e) => {
    const value = parseFloat(e.target.value);
    setInvestment(value);
    if (!isNaN(value)) {
      calculateProfits(value);
    } else {
      setDailyProfit(0);
      setSixDayProfit(0);
    }
  };

  return (
    <div className="profitCalculator">
      <div className="calculator">
        <div className="input-section">
          <h1>Returns Calculator</h1>
          <label htmlFor="investment">Enter Investment Amount: </label>
          <input
            type="number"
            id="investment"
            value={investment}
            onChange={handleInputChange}
            placeholder="Enter Amount"
          />
        </div>
        <div className="results-section">
          <div className="dailyResults">
            <p>Daily Profit: $<span>{dailyProfit.toFixed(2)}</span></p>
          </div>
          <div className="weeklyResults">
            <p>Weekly : $<span>{sixDayProfit.toFixed(2)}</span></p>
          </div>
        </div>
        <Link to='/register'><button className="joinBtn">New Member SignUp</button></Link>
      </div>
    </div>
  );
};

export default ProfitCalculator;
